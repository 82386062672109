import React, { useEffect, useState } from 'react'
import loadable from '@loadable/component'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Header from '../components/Header'
import { useLocation } from '@reach/router'
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
const Hero = loadable(() => import('../components/Home/Hero'))
const Features = loadable(() => import('../components/Home/Features'))
const Steps = loadable(() => import('../components/Home/Steps'))
const Showcase = loadable(() => import('../components/Home/Showcase'))
const Template = loadable(() => import('../components/Home/Template'))
const Migrate = loadable(() => import('../components/Home/Migrate'))
const ALaCarte = loadable(() => import('../components/Home/ALaCarte'))
// const Workshops = loadable(() => import('../components/Home/Workshops'))
const Collaborate = loadable(() => import('../components/Home/Collaborate'))
const Clients = loadable(() => import('../components/Home/Clients'))
const Partners = loadable(() => import('../components/Home/Partners'))

export default function Home({ data, pageContext }) {
  //Redirect user if is not on good lang
  const { t } = useTranslation()
  const { languages, language, changeLanguage } = useI18next()
  const steps = t('page_home_section_2_descr_list', {
    returnObjects: true,
  })
  const [firstTime, setFirstTime] = useState(false)
  const location = useLocation()
  useEffect(() => {
    const langs = languages.some(
      (item) => '/' + item + '/' === location.pathname
    )
    if (!firstTime && !langs) {
      const lang = navigator.userLanguage || navigator.language
      const tagLang = lang.split('-')[0]
      if (tagLang.includes(...languages) && language !== tagLang) {
        changeLanguage(tagLang)
      }
      setFirstTime(true)
    }
  }, [])
  const models = data.allModels.edges
  return (
    <Layout className="home" locale={pageContext.language} template="home">
      <Header></Header>
      <main>
        <Hero />
        <Features lang={pageContext.language} />
        <Steps />
        <Showcase />
        <Template data={models} lang={pageContext.language} />
        <Migrate />
        <ALaCarte />
        {/* <Workshops /> */}
        <Collaborate />
        <Clients />
      </main>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allModels(filter: { showcase: { eq: true } }) {
      edges {
        node {
          id
          order
          updatedAt
          publishedVersion
          apps
          name {
            en
          }
          miniature
          desktop
          mobile
          landscape
          showcase
          createdAt
          currentState
          currentVersion
        }
      }
    }
  }
`
